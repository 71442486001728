import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import GroupShirtPic from "/images/home-img.jpg"
import "../App.css";
import { Button, Image, Reveal } from 'semantic-ui-react'
import data from "../data.json"
import Card from "./Card"

const StyledBody = styled.div`
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    background: black;
    width: 100%;
    white-space: normal;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: center;
    color: white;
`;
const StyledBody2 = styled.div`
    flex-grow: 1;
    background: white;
    width: 100%;
    color: black;
    white-space: normal;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: center;
    padding: 0px;
`;

const StyledBanner = styled.div`
    display: flex;
    flex-direction: column;
    background: #E3C118;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    line-spacing: 0.5px;
`;

interface BodyProps {}

const Body: FC<BodyProps> = () => {

    const marquee_images = data.marquee_images;

    const products = data.products.map(product => <Card key={product.id} id={product.id} name={product.name} category={product.category} images={product.images} link={product.link}/>)

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setCounter(counter => (counter + 1) % 3);
        }, 7000);
    
        return () => {
          clearInterval(interval);
        };
      }, []);

    return (
        <div>
            <StyledBanner>
                <p className="saleBanner"><b>20% OFF SALE</b></p>
                <p className="saleBanner">USE CODE: BLC20</p>
            </StyledBanner>
            <StyledBody>
                <div className="hero-image">
                    <img src={marquee_images[counter]} alt="" className="hero-image"></img>
                </div>
                {/*<StyledBody2>
                    <h4 className="text-banner"><b>A portion of profits will be donated to the The National Police Accountability
                    Project (NPAP), The Conscious Kids Fund, and The Fair Fight.</b></h4>
                </StyledBody2>*/}
                <h4 className="text-banner"><b>A portion of profits will be donated to
                    <a href="https://www.joincampaignzero.org/" className="link"> Campaign Zero</a>, 
                    <a href="https://www.theconsciouskid.org/donate" className="link"> The Conscious Kid Children's Book Fund</a>, and 
                    <a href="https://fairfight.com/" className="link"> The Fair Fight Fund</a>.</b></h4>
            </StyledBody>
            <StyledBody>
                {products}
            </StyledBody>
            {/*<StyledBody2>
                <h2 className="text">Why We Stand with the Black Lives Matter Movement</h2>
            </StyledBody2>*/}
        </div>

    );
};

export default Body;
