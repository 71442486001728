import React from "react";
import styled from "styled-components"

const StyledFooter = styled.div`
    padding: 40px;
    background: #121111;
    color: white;
    text-align: center;
`;


function Footer() {
    return (
        <StyledFooter>
            <img src="/images/Nothing-But-Amazing-Logo.png" height="80vw"></img>
            <h3>Copyright 2020 Nothing But Amazing, LLC.</h3>
        </StyledFooter>
    );
}

export default Footer;
