import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from 'react-ga';

const StyledApp = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

function App() {
    useEffect(() => {
        ReactGA.initialize('UA-76595110-2')
        //report page view
        ReactGA.pageview('/')
    }, []);

    return (
        <Router>
            <StyledApp>
                <Header />
                <Body />
                <Footer />
            </StyledApp>
        </Router>
    );
}

export default App;
