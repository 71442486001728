import React, { FC } from "react";
import styled from "styled-components";
import "../App.css";

const StyledHeader = styled.div`
    background: black;
    color: white;
    font-size: 30px;
    width: 100%;
    white-space: normal;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: center;
`;

const StyledHeaderImage = styled.div`
    padding: 30px;
`;

interface HeaderProps {}

const Header: FC<HeaderProps> = (props) => {
    return (
        <StyledHeader>
            <StyledHeaderImage>
                <img src="/images/Black-Lives-Collection.png" alt="not here" height="100px"></img>
            </StyledHeaderImage>
            {/*<Nav />*/}
        </StyledHeader>
    );
};

export default Header;
