import React, { FC } from 'react'
import { Button, Image, Reveal } from 'semantic-ui-react'
import styled from "styled-components";
import "../App.css";
import ReactGA from 'react-ga';

const StyledCard = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    margin: 20px 10px 10px 0px;
    color: white;
    background-color: black;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
    
`;

const StyledTemplate = styled.div`
    flex: 2;
    width: 100%;
    height: auto;
`;

interface CardProps {
    id: number,
    name: string,
    category: string,
    images: Array<string>,
    link: string

}

const Card: FC<CardProps> = (props) => {

    const ClickHandler = () => {
        ReactGA.event({
            category: 'Button',
            action: `Clicked the Buy button for ${props.name} - ${props.category}`
        })
    };

    return (
        <div>
            <StyledCard>
                <Reveal animated='move'>
                    <Reveal.Content visible>
                    <Image src={props.images[0]} size='large'/>
                    </Reveal.Content>
                    <Reveal.Content hidden>
                    <Image src={props.images[1]} size='large'/>
                    </Reveal.Content>
                </Reveal>
                <StyledTemplate>
                    <h2 className="cardLine"><b>{props.name}</b></h2>
                    <h2 className="cardLineSmall"><b>{props.category}</b></h2>
                    <br />
                    <img src={props.images[2]} alt="" className="templateImage"></img>
                    <br />
                    <Button inverted size="medium" href={props.link} onClick={ClickHandler}>BUY</Button>
                </StyledTemplate>
            </StyledCard>
            <br />
        </div>
    );
}

export default Card;